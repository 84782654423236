<template>
    <v-container>
        <v-card
            elevation="2"
            outlined 
            class="blue lighten-4">
            <v-card-title class="pb-0">
                <h4 class="primary--text">Add Safety Observation</h4>
            </v-card-title>
            <v-card-text>
                <p style="margin-bottom:0px; font-size:17px;">1. Observation Type</p>
                <v-select :items="typesObservation" item-text="category" :rules="rules" v-model="type" item-value="id" style="margin-top:0px !important;"></v-select>

                <p style="margin-bottom:0px; font-size:17px;">2. Project</p>
                <v-select :items="projects" item-text="name" :rules="rules" v-model="project" item-value="id" style="margin-top:0px !important;"></v-select>

                <p style="margin-bottom:5px; font-size:17px;">3. Observation Description</p>
                <v-textarea outlined auto-grow rows="7" :rules="rules" v-model="observation" class="pb-o"></v-textarea>

                <v-file-input 
                class="pt-0 mt-0"
                v-model="upload" 
                accept="image/png, image/jpeg, image/bmp"
                color="primary"
                truncate-length="15"
                label="Add Observation Image"
                prepend-icon="mdi-camera"></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-btn style="text-transform: capitalize;margin-top:-30px;" class="primary" @click.once="submitSafety()">Submit</v-btn>
            </v-card-actions>
        </v-card>      
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    name: "AddSafety",
    data: () => ({
        observation: "",
        typesObservation:[
            {category: "Positive", id: "1"},
            {category: "Negative", id: "0"}
        ],
        type: "",
        project: "",
        upload:null,
        rules: [
            value => !!value || "Required."
        ],
        user_p : JSON.parse(localStorage.getItem('user'))
    }),
    computed:{
        ... mapGetters ( {projects : "getAllProjects"})
    },
    mounted(){
        let user = JSON.parse(localStorage.getItem('user'))
        if(user == null){
        this.$router.push('/')
        }
        this.fetchAllProjects(user.id)
    },
    methods: {
        ... mapActions (['fetchAllProjects']),
        async submitSafety () {
            let formData = new FormData();
            formData.append("type",this.type)
            formData.append("observation",this.observation)
            formData.append("project", this.project)
            formData.append("user", this.user_p.id)
            formData.append("upload",this.upload)
            axios.post(
                "https://camusatweb.ubuniworks.com/api/v1/safety",
                formData,
                { headers: {"Content-Type": "multipart/form-data"} }
            ).then((response) => {
                if(response.data.status == true){
                    Swal.fire({
                        icon: 'success',
                        title: 'Safety Observation successfully submitted',
                        text: 'You will be informed once it has been reviewed'
                    })
                    this.$router.push('/safety')
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Safety Observation submission failed',
                        text: response.data.message
                    })
                }
            })
            
        }
    }
}
</script>